'use strict';
import { CogIcon } from '@heroicons/react/20/solid';
import { DialogTitle } from '@radix-ui/react-dialog';
import cls from 'classnames';
import React, { useState } from 'react';
import { Dialog, DialogContent } from './Dialog';
import { SelectField } from './FormFields/SelectField';
import { PrimaryButton } from './PrimaryButton';

export const ExportPage = ({ creditorCodes: initialCreditorCodes }: { creditorCodes: { value: string | number; label: string }[] }) => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState<string | null>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [creditorCodes, setCreditorCodes] = useState(initialCreditorCodes);
  const [email, setEmail] = useState(true);
  const [download, setDownload] = useState(false);

  if (!creditorCodes || !creditorCodes.length) {
    setCreditorCodes([{ value: 'all', label: 'All' }]);
  }

  const exportButtonPressed = (e: any) => {
    e.preventDefault();

    if (option) {
      let fileName = 'file.xlsx';
      setLoading(true);
      fetch(`/dealers/api/downloadProductSpreadsheet?CreditorCode=${option}&email=${email}&download=${download}`)
        .then((response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/i);
            if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
            return response.blob();
          }
          return false;
        })
        .then((blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          setOpenSuccessModal(true);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form className="my-4 flex flex-col gap-y-10">
      <SelectField
        invertColor={true}
        title="Creditor Codes"
        value={option}
        onChange={(value: React.ChangeEvent<HTMLSelectElement>) => {
          setOption(value.target.value);
        }}
        options={creditorCodes}
        required={true}
      />

      <div className="grid grid-cols-2 gap-4">
        <label className="flex items-center gap-2">
          <input type="checkbox" name="email" checked={email} onChange={() => setEmail(!email)} />
          <div>Receive in email</div>
        </label>
        <label className="flex items-center gap-2">
          <input type="checkbox" name="download" checked={download} onChange={() => setDownload(!download)} />
          <div>Download</div>
        </label>
      </div>

      <PrimaryButton
        onClick={exportButtonPressed}
        className={cls('flex flex-grow-0 justify-center gap-4', loading && 'pointer-events-none opacity-50 hover:opacity-50')}
      >
        {loading && <CogIcon className="h-6 w-6 animate-spin" />}
        <span>Export Products</span>
      </PrimaryButton>

      <Dialog open={openSuccessModal}>
        <DialogContent>
          <DialogTitle className="hidden">Dealer Export</DialogTitle>
          <div className="flex flex-col gap-6">
            {email ? (
              <>
                <h2 className="text-3xl font-normal">Dealer Export emailed</h2>
                <p>Your export will be completed shortly, keep an eye on your emails in the meantime</p>
              </>
            ) : (
              <h2 className="text-3xl font-normal">Done</h2>
            )}
            <div className="flex justify-end gap-4">
              <button onClick={() => setOpenSuccessModal(false)} className="rounded-lg bg-green px-4 py-2">
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorModal}>
        <DialogContent>
          <DialogTitle className="hidden">Dealer Export Failed</DialogTitle>
          <div className="flex flex-col gap-6">
            <h2 className="text-3xl font-normal">Dealer Export Failed</h2>
            <p>Export file failed to generate. Please try submit again. If the problem persists, please contact your Administrator</p>
            <div className="flex justify-end gap-4">
              <button onClick={() => setOpenErrorModal(false)} className="rounded-lg bg-green px-4 py-2">
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </form>
  );
};
